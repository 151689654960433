<template>
  <div class="contents">
    <div class="con-body" ref="container">
        <div class="con-drone-view" ref="bigView">
            <div class="remote-station-title">
                <h3>N번 스테이션 작성 타이틀</h3>
            </div>
            <div class="con-live-view" ref="map">

            </div>
            <!-- all drone remote -->
            <div class="sdr-con">
                <!-- station remote -->
                <div class="sr-con">
                    <div class="sr-btn">
                        <div class="sr-btn-title">
                            <h2><input type="checkbox" v-model="isStationChecked"> 스테이션 원격 제어</h2>
                        </div>
                        <div v-if="!isStationChecked" class="sr-btn-false">
                            <h4>스테이션 원격 제어 활성화를<br>시켜주세요.</h4>
                        </div>
                        <div class="sr-btn-list" v-else>
                            <ul class="m-b-10">
                                <li>
                                    <div v-show="!isDoorOpened" class="sr-btn-box" @click="handleDoorOpen">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_openDock.png" alt="icon"></div>
                                        <h4>도어열기</h4>
                                    </div>
                                    <div v-show="isDoorOpened" class="sr-btn-box" @click="handleDoorClose">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_closeDock.png" alt="icon"></div>
                                        <h4>도어닫기</h4>
                                    </div>
                                </li>
                                <li>
                                    <div v-if="!isFlightStarted" class="sr-btn-box" @click="confirmFlightStart">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_flightStart.png" alt="icon"></div>
                                        <h4>비행시작</h4>
                                    </div>
                                    <div v-if="isFlightStarted" class="sr-btn-box" @click="confirmFlightStop">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_flightPause.png" alt="icon"></div>
                                        <h4>비행중지</h4>
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div class="sr-btn-box" @click="openCoordinateModal">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_locationFlight.png" alt="icon"></div>
                                        <h4>좌표비행</h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="sr-btn-box">
                                        <div :class="['sr-btn-icon', { 'flashing': isFlashing }]" @click="handleRTHClick"><img src="@/assets/images/icon_svg/ic_return_home.png" alt="icon" style="width: 25px;"></div>
                                        <h4>RETURN</h4>
                                    </div>                               
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- drone remote -->
                <div class="dr-con">
                    <div class="dr-btn">
                        <div class="dr-btn-title">
                            <h2><input type="checkbox" v-model="isDroneChecked">수동 비행 조종 모드</h2>
                        </div>
                        <div v-if="!isDroneChecked" class="dr-btn-false">
                            <h4>수동 비행 조종 모드 활성화를<br>시켜주세요.</h4>
                        </div>
                        <div class="dr-btn-form" v-else>
                            <div class="lf-btn">
                                <div class="altitude-value">
                                    <span>고도</span>
                                    <h4><span>124.3</span>m</h4>
                                </div>
                                <div class="at-key">
                                    <div class="at-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('R') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/up_key.png" alt="Up Key">
                                            </div>
                                            <h6>R</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('F') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/down_key.png" alt="Down Key">
                                            </div>
                                            <h6>F</h6>
                                        </button>
                                    </div>
                                    <div class="at-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('Q') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/r_left.png">
                                            </div>
                                            <h6>Q</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('E') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/r_right.png">
                                            </div>
                                            <h6>E</h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="can-btn">
                                <div class="at-img">
                                    <img src="@/assets/images/altitude_box.png">
                                </div>
                                <div class="angle-info">
                                    <h4><span>{{ droneAngle }}</span>˚</h4>
                                    <div class="angle-img">
                                        <img :style="{ transform: 'translate(-50%, 0%) rotate(' + rotationAngle + 'deg)' }" src="@/assets/images/angle_circle.png" class="angle_img">
                                        <img src="@/assets/images/icon_svg/ic_droneMark.png" class="droneMark_img">
                                        <img src="@/assets/images/icon_svg/location_mark.png" class="lm_img">
                                    </div>
                                </div>
                            </div>
                            <div class="rf-btn">
                                <div class="op-btn">
                                    <button :class="{ active: isActive[0] }" @click="toggleDroneActive(0)"><img src="@/assets/images/icon_svg/ic_beacon.png"></button>
                                    <button :class="{ active: isActive[1] }" @click="toggleDroneActive(1)"><img src="@/assets/images/icon_svg/ic_light.png"></button>
                                    <button :class="{ active: isActive[2] }" @click="toggleDroneActive(2)"><img src="@/assets/images/icon_svg/ic_laser.png"></button>
                                    <button :class="{ active: isActive[3] }" @click="toggleDroneActive(3)"><img src="@/assets/images/icon_svg/ic_speaker.png"></button>
                                </div>
                                <div class="dt-key">
                                    <div class="dt-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('W') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/up_key.png">
                                            </div>
                                            <h6>W</h6>
                                        </button>
                                    </div>
                                    <div class="dt-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('A') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/left_key.png">
                                            </div>
                                            <h6>A</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('S') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/down_key.png">
                                            </div>
                                            <h6>S</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('D') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/right_key.png">
                                            </div>
                                            <h6>D</h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- camera remote -->
                <div class="cr-con">
                    <div class="cr-btn">
                        <div class="cr-btn-title">
                            <h2><input type="checkbox" v-model="isCameraChecked">카메라 원격 제어</h2>
                        </div>
                        <div v-if="!isCameraChecked" class="cr-btn-false">
                            <h4>카메라 원격 제어 활성화를<br>시켜주세요.</h4>
                        </div>
                        <div class="cr-radar-form" v-else>
                            <div class="cr-radar">
                                <div class="cr-radar-date">
                                    <h4><img src="@/assets/images/icon_svg/ic_rotateZ.png" style="width: 15px;"><span>{{ rotationCameraAngle }}</span>˚</h4>
                                    <h4><img src="@/assets/images/icon_svg/ic_rotateY.png" style="height: 15px;padding-left: 3px;"><span>{{ rotationYAxisAngle }}</span>˚</h4>
                                </div>
                                <div class="cr-radar-img">
                                    <div class="cr-radar-axis" :style="{ transform: `translate(-50%, -50%) rotateX(${rotationXAxisAngle}deg) rotateY(${rotationYAxisAngle}deg) rotateZ(${rotationCameraAngle}deg)` }">
                                        <img src="@/assets/images/icon_svg/ic_cr_radar.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="cr-key">
                                <button class="key-btn" :class="{ active: activeKeys.includes('Z') }">
                                    <div class="key-img">
                                        <img src="@/assets/images/icon_svg/left_key.png">
                                    </div>
                                    <h6>Z</h6>
                                </button>
                                <button class="key-btn" :class="{ active: activeKeys.includes('X') }">
                                    <div class="key-img">
                                        <img src="@/assets/images/icon_svg/up_key.png">
                                    </div>
                                    <h6>X</h6>
                                </button>
                                <button class="key-btn" :class="{ active: activeKeys.includes('C') }">
                                    <div class="key-img">
                                        <img src="@/assets/images/icon_svg/down_key.png">
                                    </div>
                                    <h6>C</h6>
                                </button>
                                <button class="key-btn" :class="{ active: activeKeys.includes('V') }">
                                    <div class="key-img">
                                        <img src="@/assets/images/icon_svg/right_key.png">
                                    </div>
                                    <h6>V</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="con-drone-set">
            <button @click="controlModeOFf"><img src="@/assets/images/icon_svg/ic_control_off.png" alt="icon" />원격 종료 및 나가기</button>
            <div class="con-live-box">
                <div class="vodview" ref="smView">
                    <button class="switching-btn" @click="switchViews">
                        <img src="@/assets/images/icon_svg/ic_switching.png">
                    </button>
                    <div class="video-box" ref="video"> <!--영상 송출 유무 표현-->
                        <div class="play-on" v-if="false">
                            <!-- wide value -->
                            <div class="camera-value" ref="wideData">
                                <span>WIDE</span>
                                <span>1.0x</span>
                            </div>
                        </div>
                        <div class="play-off">
                            <img src="@/assets/images/play-off-icon.png" alt="icon" />
                            <h2>영상 신호 없음</h2>
                        </div>
                    </div>
                    <div class="video-info">
                        <div class="video-top">
                            <!--종류에 따른 이미지 제어-->
                            <div class="emphysema-img">
                                <div class="drone-image">
                                    <img src="@/assets/images/drone_img/dji_matrice_300_RTK.png" alt="drone" />
                                </div>
                            </div>
                            <div class="battery-info">
                                <h4>
                                    <span>0</span>
                                    <span>%</span>
                                </h4>
                                <ul>
                                    <li v-for="index in 5" :key="index"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="drone-video-info">
                            <div class="video-title">
                                <h2>
                                    <span>모델</span>
                                    <span>-</span>
                                </h2>
                                <h4>
                                    <span>{{$t("device-data-manager-dept-name")}} </span>
                                    <span>-</span>
                                </h4>
                                <h4>
                                    <span>조종자 </span>
                                    <span>-</span>
                                </h4>
                            </div>
                            <div class="video-value">
                                <div class="value-box">
                                    <h4>고도</h4>
                                    <h2><span class="dataValue">-</span>m</h2>
                                </div>
                                <div class="value-box">
                                    <h4>거리</h4>
                                    <h2><span class="dataValue">-</span>m</h2>
                                </div>
                                <div class="value-box">
                                    <h4>수평</h4>
                                    <h2><span class="dataValue">-</span>m/s</h2>
                                </div>
                                <div class="value-box">
                                    <h4>수직</h4>
                                    <h2><span class="dataValue">-</span>m/s</h2>
                                </div>
                            </div>
                            <div class="video-coordinate">
                                <div class="coordinate-row">
                                    <h4>위도</h4>
                                    <h2><span class="dataValue">-</span></h2>
                                </div>
                                <div class="coordinate-row">
                                    <h4>경도</h4>
                                    <h2><span class="dataValue">-</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 카메라 조작 -->
            <div class="con-camera">
                <div class="camera-btn">
                    <button 
                    v-for="button in buttons" 
                    :key="button" 
                    :class="{ active: cameraSelectButton === button }"
                    @click="cameraButton(button)">
                    {{ button }}
                    </button>
                </div>
                <div class="camera-set">
                    <div class="set-box">
                        <div class="csb-title">
                            <h4>Focus Setting</h4>
                            <div class="csb-select">
                                <select>
                                    <option>MANUAL</option>
                                </select>
                                <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                            </div>
                        </div>
                        <div class="csb-remote">
                            <div class="csb-form">
                                <h4>Focus<span>{{ focusValue }}</span></h4>
                                <div class="csb-btn">
                                    <button @click="increaseFocus">+</button>
                                    <button @click="decreaseFocus">-</button>
                                </div>
                            </div>
                            <div class="csb-input">
                                <input 
                                    type="range" 
                                    v-model="focusValue" 
                                    min="0" 
                                    max="100" 
                                    @input="updateFocus"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="set-box">
                        <div class="csb-title">
                            <h4>Zoom Setting</h4>
                        </div>
                        <div class="csb-remote">
                            <div class="csb-form">
                                <h4>Zoom<span>{{ zoomValue }}</span></h4>
                                <div class="csb-btn">
                                    <button @click="increaseZoom">+</button>
                                    <button @click="decreaseZoom">-</button>
                                </div>
                            </div>
                            <div class="csb-input">
                                <input 
                                    type="range" 
                                    v-model="zoomValue" 
                                    min="0" 
                                    max="100" 
                                    @input="updateZoom"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="set-box">
                        <div class="csb-title">
                            <h4>TH Setting</h4>
                            <div class="csb-select">
                                <select v-model="thSelectOption" @change="updateImage">
                                    <option value="arctic">ARCTIC</option>
                                    <option value="black_hot">BLACK HOT</option>
                                    <option value="cold_spot">COLD SPOT</option>
                                    <option value="fulgurite">FULGURITE</option>
                                    <option value="gray">GRAY</option>
                                    <option value="hot_iron">HOT IRON</option>
                                    <option value="hot_metal">HOT METAL</option>
                                    <option value="hot_spot">HOT SPOT</option>
                                    <option value="iron_red">IRON RED</option>
                                    <option value="medical">MEDICAL</option>
                                    <option value="rainbow">RAINBOW</option>
                                    <option value="rainbow_1">RAINBOW 1</option>
                                    <option value="rainbow_2">RAINBOW 2</option>
                                    <option value="tint">TINT</option>
                                    <option value="white_hot">WHITE HOT</option>
                                </select>
                                <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                            </div>
                        </div>
                        <div class="csb-remote">
                            <div class="csb-th-bar">
                                <img :src="imageSrc">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 스테이션 영상 -->
            <div class="con-station-view">
                <div class="station-live">
                    <div class="play-on" v-if="false"></div>
                    <div class="play-off">
                        <img src="@/assets/images/play-off-icon.png" alt="icon" />
                        <h2>영상 신호 없음</h2>
                    </div>
                </div>
                <div class="sv-con">
                    <div class="sv-img">
                        <img src="@/assets/images/drone_img/dock2.png" alt="station" />
                    </div>
                    <div class="sv-info">
                        <h4>1번 스테이션</h4>
                        <div class="sv-value">
                            <div class="sv-situation">
                                <div class="sv-situation-row">
                                    <h4>상태</h4>
                                    <h5>
                                        <span class="sv-mark sv-good"></span>
                                        <span>정상작동</span> <!-- 이상있으면 "이상감지" -->
                                    </h5>
                                </div>
                                <div class="sv-situation-row">
                                    <h4>강수단계</h4>
                                    <h5>
                                        <span>0</span>단계 <!-- 진행없으면 "-" -->
                                    </h5>
                                </div>
                            </div>
                            <div class="sv-situation">
                                <div class="sv-situation-row m-b-0">
                                    <h4>풍량</h4>
                                    <h5>
                                        <span>0.0</span>m/s <!-- 진행없으면 "-" -->
                                    </h5>
                                </div>
                                <div class="sv-situation-row m-b-0">
                                    <h4>통신속도</h4>
                                    <h5>
                                        <span>0.0</span>MB/s <!-- 진행없으면 "-" -->
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal -->
    <div v-if="isModalVisible" class="modal">
        <div class="dock-modal-content">
                <div class="loader"></div>
                <h3>{{ modalMessage }}</h3>
        </div>
    </div>

    <div v-if="isCoordinateOpen" class="modal">
        <div class="cd-modal-content">
            <div class="cd-modal-title">
                <h2>좌표비행 설정</h2>
                <span class="close-btn" @click="coordinateCloseModal">&times;</span>
            </div>
            <div class="cd-modal-body">
                <div class="cd-form">
                    <label>{{$t("mission-data-lat")}}</label>
                    <input type="text" value="123.5469854" disabled>            
                </div>
                <div class="cd-form">
                    <label>{{$t("mission-data-long")}}</label>
                    <input type="text" value="23.5469854" disabled>            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-targetHeight")}}</label>
                    <input type="text">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-securityTakeoffHeight")}}</label>
                    <input type="text">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-rthAltitude")}}</label>
                    <input type="text">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-rcLostAction")}}</label>
                    <select>
                        <option>HOVER</option>
                        <option>LAND</option>
                        <option>RETURN_HOME</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-maxSpeed")}}</label>
                    <input type="text">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-rthMode")}}</label>
                    <select>
                        <option>PRESET_HEIGHT</option>
                        <option>OPTIMAL_HEIGHT</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-commanderModeLostAction")}}</label>
                    <select>
                        <option>EXECUTE_RC_LOST_ACTION</option>
                        <option>CONTINUE</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-commanderFlightMode")}}</label>
                    <select>
                        <option>SMART_HEIGHT</option>
                        <option>SETTING_HEIGHT</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">            
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-commanderFlightHeight")}}</label>
                    <input type="text">            
                </div>
            </div>
            <div class="cd-modal-btn">
                <button class="submit-btn">확인</button>
                <button class="close-btn" @click="coordinateCloseModal">취소</button>
            </div>
        </div>
    </div>

    <div v-if="isRTHModalOpen" class="modal" @click.self="closeRTHModal">
      <div class="rth-modal-content">
        <p>RETURN-TO-HOME 실행이 완료되었습니다.</p>
        <button class="close-btn" @click="closeRTHModal">확인</button>
      </div>
    </div>
    <div v-if="isRTHStopModalOpen" class="modal" @click.self="closeRTHStopModal">
      <div class="rth-modal-content">
        <p>RETURN-TO-HOME이 중단되었습니다.</p>
        <button class="close-btn" @click="closeRTHStopModal">확인</button>
      </div>
    </div>
  </div>
</template>
<style>
.m-b-10{margin-bottom: 10px;}
body{background: #101010;}

.remote-station-title{background: #101010cc; padding: 5px 40px; border-radius: 5px; position: absolute; top: 10px; left: 50%;transform: translate(-50%, 0%); z-index: 100;}
.remote-station-title > h3{font-size: 2rem;font-weight: bold;color: #fff;}

.con-drone-set > button{width: 100%; border: 0;padding: 0px 20px;background: #3c3c3c;border-radius: 5px;color: #fff;font-size: 1.4rem;height: 45px; margin-bottom: 15px;}
.con-drone-set > button > img{width: 16px;margin-right: 10px;}

.con-body{display: flex;justify-content: space-between;align-items: stretch;margin-top: 15px;}

.con-drone-view{position: relative; width: calc(100% - 390px);height: calc(100vh - 30px);;background: #232323;}
.con-drone-set{width: 375px;}

.live-mark{position: absolute;background: #f42525;padding: 5px 20px;border-radius: 5px;color: #fff;font-weight: bold;top: 20px;left: 20px;}
.camera-value{position: absolute;top: 20px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 10px 20px;color: #fff;font-size: 1.4rem;border-radius: 5px;}
.camera-value > span{margin: 0 5px;}

.sdr-con{position: absolute;z-index: 100;bottom: 20px;left: 50%;transform: translate(-50%, 0%);display: flex;justify-content: space-between;align-items: center;}

.con-live-view{width: 100%;height: 100%;}
/* remote btn */
.sr-con{width: 260px;height: 290px;margin-right: 15px;}
.sr-con > .sr-btn{background: #101010cc;padding: 15px;border-radius: 5px;}
.sr-con > .sr-btn > .sr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.sr-con > .sr-btn > .sr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}
.sr-con > .sr-btn > .sr-btn-title > button{background: #0080ff;border: 0;border-radius: 5px;padding: 0 15px;color: #fff;height: 30px;font-size: 1.4rem;}

.sr-con > .sr-btn > .sr-btn-list > ul{display: flex;justify-content: space-evenly;align-items: center;background: #232323;padding: 15px 0;border-radius: 5px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li{display: flex;flex-direction: column;align-items: center;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon{width: 50px;height: 50px;background: #101010;border-radius: 50px;display: flex;align-items: center;justify-content: center;margin-bottom: 10px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon:hover{background: #0080ff;cursor: pointer;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon > img{width: 18px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > h4{font-size: 1.2rem;color: #fff;font-weight: 300;}

.sr-btn-false{width: 230px;height: 222px;background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.sr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.dr-con{width: auto;height: 290px;margin-right: 15px;}
.dr-btn{background: #101010cc;padding: 15px;border-radius: 5px;}
.dr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.dr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}
.dr-btn-form{display: flex;justify-content: space-between;align-items: center;height: 222px;}
.dr-btn-form > .lf-btn{display: flex;flex-direction: column;align-items: flex-end;margin-right: 30px;}
.dr-btn-form > .lf-btn > .altitude-value{margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-end;}
.dr-btn-form > .lf-btn > .altitude-value > span{font-size: 1.4rem;font-weight: 300;color: #3dffab;}
.dr-btn-form > .lf-btn > .altitude-value > h4{font-size: 1.4rem;color: #3dffab;}
.dr-btn-form > .lf-btn > .altitude-value > h4 > span{font-size: 2rem;margin-right: 5px;}
.dr-btn-form > .lf-btn > .at-key{display: flex;flex-direction: column;flex-wrap: wrap;}
.dr-btn-form > .lf-btn > .at-key > .at-key-row{display: flex;align-items: center;}

.can-btn{display: flex;align-items: center;margin-right: 30px;}
.at-img{margin-right: 10px;}
.angle-info{position: relative;}
.angle-info > h4{position: absolute;left: 52%;transform: translate(-50%, 0%);top: -30px;color: #3df076;font-size: 2rem;}
.angle-info > h4 > span{font-size: 2.2rem;}
.angle-img{position: relative;width: 150px;height: 150px;}
.angle-img > .angle_img{width: 100%;left: 50%;top: 0%;transform: translate(-50%, -50%);position: absolute;}
.angle-img > .droneMark_img{position: absolute;width: 35%;top: 10%;left: 50%;transform: translate(-50%, 50%);}
.angle-img > .lm_img{position: absolute;width: 10px;top: 20px;left: 50%;transform: translate(-50%, 50%);}

.op-btn{display: flex;align-items: center;}
.op-btn > button{margin: 0 3px;width: 38px;height: 38px;border: 0;background: #232323;display: flex;justify-content: center;align-items: center;border-radius: 3px;}
/* .op-btn > button:hover{background: #0080ff;} */
.op-btn > button.active{background: #0080ff;}
.op-btn > button > img{width: 18px;}
.dt-key{margin-top: 20px;}
.dt-key-row{display: flex;justify-content: center;align-items: center;}

.dr-btn-false{width: 515px;height: 222px;background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.dr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.cr-con{width: 250px;height: 290px;}
.cr-btn{background: #101010cc;padding: 15px;border-radius: 5px;}
.cr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.cr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}
.cr-radar{display: flex;justify-content: center;margin-bottom: 20px;padding-top: 10px;position: relative;}
.cr-radar-img{width: 130px;height: 130px;background: #000;border-radius: 100px;position: relative;border: 1px solid #ddd;}
.cr-radar-img::before{content: '';position: absolute;border: 0.5px solid #ddd;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.cr-radar-img::after{content: '';height: 100%;width: 1px;position: absolute;background: #ddd;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.cr-radar-axis{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 102;}
.cr-radar-axis > img{position: absolute;width: 45px;transition: 1s all;transform: translate(-50%, -100%);top: 50%;left: 50%;}
.cr-key{display: flex;justify-content: space-between;align-items: center;}

.cr-btn-false{width: 220px;height: 222px;background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.cr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.cr-radar-date{position: absolute;top: 0;right: 0;}
.cr-radar-date > h4{font-size: 1.2rem;color: #fff;font-weight: 300;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;width: 45px;}
.cr-radar-date > h4 > img{margin-right: 10px;}
.cr-radar-date > h4 > span{font-size: 1.2rem;color: #fff;font-weight: 300;}

.key-btn{background: #232323;display: flex;flex-direction: column;align-items: center;padding: 5px;margin: 3px;border-radius: 3px; height: auto;border: 0;}
.key-img{width: 15px;height: 15px; margin-bottom: 5px;padding-top: 2px;text-align: center;}
.key-img > img{width: 10px;}
.key-btn > h6{background: #101010;font-size: 1.6rem;width: 33px;height: 27px;text-align: center;border-radius: 3px;display: flex;justify-content: center;align-items: center;color: #fff;}
.key-btn.active {background:#0080ff;}
.key-btn.active > h6 {background: #0249cb;}

.flashing {
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
    background-color: #101010;
  }
  50% {
    background-color: #f42525;
  }
  100% {
    background-color: #101010;
  }
}

/* drone css */
.con-live-box{width: 375px;height: 290px;background: #232323;margin-bottom: 15px;}
.vodview{position: relative; display: flex;height: 100%;background: #232323;margin-bottom: 15px;flex-direction: column;}
.switching-btn{position: absolute; right: 0;top:0;z-index: 100;background: #101010cc;}
.video-box{width: 100%;height: 100%;background: #000000;position: relative;}
.video-box > .play-on{position: relative; width: 100%;height: 100%;}
.video-box > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.video-box > .play-off > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;}
.video-box > .play-off > img{width: 25px;}

.video-box > .play-off-4{width: 100%;text-align: center;padding-top: 56.25%;}
.video-box > .play-off-4 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-4 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-box > .play-off-9{width: 100%;text-align: center;padding-top: 56.25%;}
.video-box > .play-off-9 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-9 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-info{width:100%;color: #fff;padding: 0px;display: flex;flex-direction: row;justify-content: space-between;border-top: 1px solid #4f4f4f;}
.video-info > .video-top{display: flex;align-items: center;justify-content: space-between; margin-bottom: 0px;background: #232323;flex-direction: column;width: 80px;border-right: 1px solid #4f4f4f;}
.video-info > .video-top > .emphysema-img{width: 70px;background: #232323;border-radius: 5px;}
.video-info > .video-top > .emphysema-img > .drone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 0px;text-align: center;display: flex;align-items: center;}
.video-info > .video-top > .emphysema-img > .phone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 5px;text-align: center;}
.video-info > .video-top > .emphysema-img > .drone-image > img{width: 100%;}
.video-info > .video-top > .emphysema-img > .phone-image > img{height: 100%;}

.video-info > .video-top > .battery-info{display: flex;align-items: center;margin-bottom: 5px;}
.video-info > .video-top > .battery-info > ul{display: flex;margin-bottom: 0px;}
.video-info > .video-top > .battery-info > ul > li{height: 18px;width: 5px;background: #878787;margin-right: 2px;border-radius: 2px;}
.video-info > .video-top > .battery-info > ul > li.on{background: #1cdfa0;}
.video-info > .video-top > .battery-info > ul > li.off{background: #878787}
.video-info > .video-top > .battery-info > h4{text-align: right;margin-right: 5px;}
.video-info > .video-top > .battery-info > h4 > span{font-size: 1.1rem;font-weight: 400;}

.video-info > .drone-video-info{width: calc(100% - 80px);}

.video-info > .drone-video-info > .video-title{border-bottom: 1px solid #4f4f4f;padding: 7px 0px 7px 10px;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-title > h2{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px; margin-right: 10px;}
.video-info > .drone-video-info > .video-title > h2 > span{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px;}
.video-info > .drone-video-info > .video-title > h2 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}
.video-info > .drone-video-info > .video-title > h4{font-size: 1.1rem;font-weight: 400;margin-bottom: 0px;display: flex; margin-right: 10px;}
.video-info > .drone-video-info > .video-title > h4:nth-child(3){margin-bottom: 0;}
.video-info > .drone-video-info > .video-title > h4 > span{font-size: 1.1rem;}
.video-info > .drone-video-info > .video-title > h4 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}

.video-info > .drone-video-info > .video-value{display: flex;flex-wrap: wrap;align-items: center;padding-top: 7px;padding-left: 10px;margin-bottom: 6px;}
.video-info > .drone-video-info > .video-value > .value-box{width: 25%;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-value > .value-box > h4{font-size: 1.1rem;color: #c9c9c9;margin-bottom: 0px;margin-right: 5px;font-weight: 400;}
.video-info > .drone-video-info > .video-value > .value-box > h4 > span{font-size: 1.1rem;}
.video-info > .drone-video-info > .video-value > .value-box > h2{font-size: 1.1rem;font-weight: 300;margin-bottom: 0px}
.video-info > .drone-video-info > .video-value > .value-box > h2 > span{font-size: 1.2rem;}

.video-info > .drone-video-info > .video-coordinate{padding-left: 10px;display: flex;justify-content: space-between;align-items: center;padding-bottom: 2px;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row{display: flex;align-items: center;margin-bottom: 0px;width: 50%;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h4{font-size: 1.1rem;color: #c9c9c9;margin-right: 5px; font-weight: 400;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2{margin: 0;font-weight: 300;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2 > span{font-size: 1.2rem;}


/* camera css */
.con-camera{width: 375px;height: 273px;background: #232323;border-radius: 5px;margin-bottom: 15px;padding: 10px;}
.camera-btn{display: flex; margin-bottom: 10px;}
.camera-btn > button{width: 70px;border: 0;margin-right: 5px;border-radius: 3px;color: #c9c9c9;}
.camera-btn > button.active{background: #0080ff;color: #fff;}
.camera-set{height: 210px;max-height: 210px;overflow-y: auto;}
.camera-set::-webkit-scrollbar{width: 8px;}
.camera-set::-webkit-scrollbar-thumb{background-color: #707070;border-radius: 4px;}
.camera-set::-webkit-scrollbar-track{border-radius: 2px;background: #1b1b1b;border: 1px solid #1b1b1b;}
.camera-set > .set-box{background: #3c3c3c;padding: 10px;border-radius: 5px;margin-bottom: 10px;}
.csb-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;}
.csb-title > h4{font-size: 1.4rem;color: #fff;font-weight: 300;}
.csb-title > .csb-select{position: relative;}
.csb-title > .csb-select > select{background: none;color: #fff;font-size: 1.2rem;width: auto;border: 0;}
.csb-title > .csb-select > select > option{background: #232323;color: #c9c9c9;}
.csb-title > .csb-select > img{position: absolute;top: 13px;right: 5px;}
.csb-remote > .csb-form{display: flex;justify-content: space-between;align-items: center;}
.csb-remote > .csb-form > h4{font-size: 1.2rem;color: #c9c9c9;font-weight: 300;}
.csb-remote > .csb-form > h4 > span{font-size: 1.4rem;color: #fff;margin-left: 10px;}
.csb-remote > .csb-form > .csb-btn > button{width: 30px;height: 30px;color: #fff;font-size: 2rem;background: #101010;border: 0;margin-left: 5px;border-radius: 5px;}
.csb-remote > .csb-input{width: 100%;margin-top: 10px;margin-bottom: 10px;}
.csb-remote > .csb-input > input{width: 100%;}
.csb-remote > .csb-th-bar{width: 100%;margin: 15px 0 15px 0;}
.csb-remote > .csb-th-bar > img{width: 100%;height: 25px;}


/* station */
.con-station-view{width: 375px;height: 290px;background: #232323;}

.station-live{width: 100%;height: 200px;background: #000000;position: relative;}
.station-live > .play-on{width: 100%;height: 100%;}
.station-live > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.station-live > .play-off > img{width: 25px;}
.station-live > .play-off > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;}

.sv-con{width: 100%; height: 90px;color: #fff;padding: 0px;display: flex;flex-direction: row;justify-content: space-between;border-top: 1px solid #4f4f4f;}
.sv-con > .sv-img{width: 80px;border-right: 1px solid #4f4f4f;display: flex;align-items: center;justify-content: center;}
.sv-con > .sv-img > img{width: 50px;}
.sv-con > .sv-info{width: calc(100% - 80px);}
.sv-con > .sv-info > h4{font-size: 1.4rem; font-weight: 300;padding: 5px 0 5px 10px;margin-bottom: 8px;}
.sv-con > .sv-info > .sv-value{display: flex;justify-content: space-between;padding: 0 10px;flex-direction: column;}
.sv-con > .sv-info > .sv-value > .sv-situation{display: flex;justify-content: space-between;align-items: center;width: 100%;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row{margin-bottom: 8px;display: flex;justify-content: space-between;align-items: center;width: 48%;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h4{font-size: 1.2rem;font-weight: 300;color: #c9c9c9;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5{display: flex;justify-content: space-between;align-items: center;font-size: 1.2rem;font-weight: 300;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark{display: inline-block; width: 10px;height: 10px;background: #ddd;border-radius: 10px;margin-right: 5px;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-good{background: #1cdfa0;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-bad{background: #ff3d3d;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > span{font-size: 1.2rem;font-weight: 300;}

/* modal */
.modal {position: fixed;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */display: flex;justify-content: center;align-items: center;}
.dock-modal-content {display: flex; flex-direction: column;align-items: center;justify-content: space-between;width: 400px; height: 150px; background: #232323;padding: 30px;border-radius: 8px;text-align: center; color: #fff;}
.dock-modal-content > h3{font-size: 1.4rem; font-weight: 400;}
.loader {width: 120px;height: 20px;-webkit-mask:linear-gradient(90deg,#000 70%,#0000 0) 0/20%;background:linear-gradient(#1cdfa0 0 0) 0/0% no-repeat#ddd;animation: l4 2s infinite steps(6);}
@keyframes l4 {100% {background-size:120%}}

.cd-modal-content{width: 800px; height: auto; background: #232323;padding: 30px;border-radius: 5px;color: #fff;}
.cd-modal-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;padding-bottom: 10px;border-bottom: 1px solid #707070;}
.cd-modal-title > h2{margin: 0;font-size: 1.6rem;}
.cd-modal-title > span{font-size: 2rem;}
.cd-modal-body{margin-bottom: 20px;padding-bottom: 10px;border-bottom: 1px solid #707070;height: 450px;max-height: 450px;overflow-y: auto;padding-right: 10px;}
.cd-modal-body::-webkit-scrollbar{width: 8px;}
.cd-modal-body::-webkit-scrollbar-thumb{background-color: #707070;border-radius: 4px;}
.cd-modal-body::-webkit-scrollbar-track{border-radius: 2px;background: #1b1b1b;border: 1px solid #1b1b1b;}
.cd-form{position: relative; display: flex; justify-content: flex-start; align-items: center;margin-bottom: 15px;}
.cd-form > label{font-size: 1.4rem;width: 30%; color: #c9c9c9;}
.cd-form > input{margin-bottom: 0;width: 70%; height: 40px;color: #fff;border: 1px solid #707070;border-radius: 5px;font-size: 1.4rem;}
.cd-form > input:disabled{background: #101010;color: #969696;}
.cd-form > select{margin-bottom: 0;width: 70%;height: 40px;background: none;color: #fff;border: 1px solid #707070;border-radius: 5px;font-size: 1.4rem;font-weight: 300;}
.cd-form > select > option{background: #232323;color: #c9c9c9;font-size: 1.4rem;}
.cd-form > img{position: absolute;top: 17px;right: 10px;}
.cd-modal-btn{display: flex;justify-content: center;align-items: center;padding-top: 10px;}
.cd-modal-btn > .submit-btn{font-size: 1.4rem;background: #0080ff;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}
.cd-modal-btn > .close-btn{font-size: 1.4rem;background: #3c3c3c;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}

.rth-modal-content{display: flex; flex-direction: column;align-items: center;justify-content: space-between;width: 400px; height: 150px; background: #232323;padding: 30px;border-radius: 8px;text-align: center; color: #fff;}
.rth-modal-content > p{font-size: 1.4rem;}
.rth-modal-content > .close-btn{font-size: 1.4rem;background: #0080ff;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}

</style>
<script>
import moment from "moment";
// import Map from "@/components/map/Map";

import {stationPopup} from "@/util/popup";
import {ko} from "vuejs-datepicker/dist/locale";

stationPopup

export default {
    components: {},
    name: "Main",
    props: {
        eventId: {
        type: String,
        default: "",
        },
        address: {
        type: String,
        default: "",
        },
        weatherList : {
        type: Array,
        default: null
        },
        updatedDate : {
        type: String,
        default: ""
        },
    },
    data() {
        return {
        //index: 0,
        moment: moment,
        loadMap : 'kakao',
        viewSrc: "",

        isdrModelShow:true,
        eventInfo : null,
        contentsId: null,
        thSelectOption:'arctic',
        imageSrc: require("../../assets/images/thermal_img/thermal_arctic.png"), 

        //key event
        activeKeys: [],
        rotationAngle: 0,
        droneAngle : 0,
        rotationCameraAngle: 0, // 현재 회전 각도
        rotationXAxisAngle: 0,
        rotationYAxisAngle: 0,
        rotationStep: 2.5, // 각 단계당 회전 각도
        isActive: [false, false, false, false],
        isFlightStarted: false,
        isDoorOpened: false, // 초기 도어 상태 (닫힘)
        isModalVisible: false, // 모달 창 표시 여부
        modalMessage: '', // 모달에 표시될 메시지
        isCoordinateOpen: false,
        isFlashing: false, // Controls the flashing effect
        isRTHModalOpen: false,
        isRTHStopModalOpen: false,
        wideData: {top: '20px'},


        location: [],
        lat : null,
        lng : null,
        buttons: ['WIDE', 'ZOOM', 'TH', 'PIP'], // 버튼 이름 리스트
        cameraSelectButton: 'WIDE', // 선택된 버튼의 이름
        locationSize : null,
        focusValue: 27.4,
        zoomValue: 14.5,
        isStationChecked: false,
        isDroneChecked: false,
        isCameraChecked: false,
        onIndexes: [1,2,3,4,5,6],
        ko: ko,
        startYmd: null,
        endYmd: null,
        currentPage: 0,
        size: 10,
        keywordType: "all",
        keyword: "",
        total: 0,

        windowWidth: null,
        checkedStations: []
        };
    },
    computed: {
        axisClass() {
            return {
                'z-axis': this.axisDirection === 'Z' || this.axisDirection === 'V',
                'x-axis': this.axisDirection === 'X' || this.axisDirection === 'C'
            };
        }
    },
    watch : {
        currentLang(value){
        this.initMapData(value);
        },
        windowWidth(){
        this.windowWidth = window.innerWidth;
      
        }
    },
    mounted() {
        console.log("mounted")
        // await this.getSDList();
        //key
        this.domAddEventHandle(window,'keydown',this.testOnKeyPress);
        this.domAddEventHandle(window, 'keyup', this.handleKeyup);
    },
    beforeDestroy() {
        this.domRemoveEventHandle(window, 'keydown', this.testOnKeyPress);
        this.domRemoveEventHandle(window, 'keyup', this.handleKeyup);
    },
    created() {
        this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
        this.windowWidth = window.innerWidth;
       
    },
    methods: {
        controlModeOFf(){
            const userConfirmed = confirm('드론 원격 조종모드를 종료 하시겠습니까?')

            if(userConfirmed){
              this.$router.push({ name: "stationControl"})
            } else {
                console.log('사용자가 취소를 선택했습니다.')
            }
        },
        isOn(index){
            return this.onIndexes.includes(index);
        },
        cameraButton(button) {
            this.cameraSelectButton = button; // 선택된 버튼 업데이트
        },
        increaseFocus() {
            if (this.focusValue < 100){
                this.focusValue = parseFloat((this.focusValue + 1).toFixed(1))
            }
        },
        decreaseFocus() {
            if (this.focusValue > 0) {
                this.focusValue = parseFloat((this.focusValue - 1).toFixed(1));
            }
        },
        updateFocus(event) {
            this.focusValue = parseFloat(event.target.value);
        },
        increaseZoom() {
            if (this.zoomValue < 100){
                this.zoomValue = parseFloat((this.zoomValue + 1).toFixed(1))
            }
        },
        decreaseZoom() {
            if (this.zoomValue > 0) {
                this.zoomValue = parseFloat((this.zoomValue - 1).toFixed(1));
            }
        },
        updateZoom(event) {
            this.zoomValue = parseFloat(event.target.value);
        },
        updateImage() {
            switch (this.thSelectOption) {
                case 'arctic':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_arctic.png");
                break;
                case 'black_hot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_black_hot.png");
                break;
                case 'cold_spot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_cold_spot.png");
                break;
                case 'fulgurite':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_fulgurite.png");
                break;
                case 'gray':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_gray.png");
                break;
                case 'hot_iron':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_iron.png");
                break;
                case 'hot_metal':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_metal.png");
                break;
                case 'hot_spot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_spot.png");
                break;
                case 'iron_red':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_iron_red.png");
                break;
                case 'medical':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_medical.png");
                break;
                case 'rainbow':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow.png");
                break;
                case 'rainbow_1':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow_1.png");
                break;
                case 'rainbow_2':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow_2.png");
                break;
                case 'tint':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_tint.png");
                break;
                case 'white_hot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_white_hot.png");
                break;
                default:
                this.imageSrc = require("../../assets/images/thermal_img/thermal_arctic.png");
            }
        },
        domAddEventHandle(target,type,eventListner){
            console.log("domAddEventHandle" , target,type)
            if(target.addEventListener){
                target.addEventListener(type,eventListner,true)
            }else{
                target.attachEvent('on' + type, eventListner, true);
            }
        },
        domRemoveEventHandle(target,type,eventListner){
            if(target.removeEventListener){
                target.removeEventListener(type,eventListner,true)
            }else{
                target.detachEvent('on' + type, eventListner, true);
            }
        },
        testOnKeyPress(event){
            console.log(event)
            console.log("Key pressed:", event.key);
            const key = event.key.toUpperCase();
            // console.log("Key code:", event.keyCode);
            if (['R', 'F', 'Q', 'E', 'W', 'A', 'S', 'D', 'Z', 'X', 'C', 'V'].includes(key)) {
                if (!this.activeKeys.includes(key)) {
                    this.activeKeys.push(key);
                    this.triggerAction(key);
                    this.applyRotation(key);
                }
            }

            if (key === 'E') {
                this.droneAngle += 1;
            } else if (key === 'Q') {
                this.droneAngle -= 1;
            }
            this.rotationAngle = this.droneAngle * -1
            this.triggerAction(key);
            this.applyRotation(key);
            

        },
        handleKeyup(event) {
            const key = event.key.toUpperCase();
            const index = this.activeKeys.indexOf(key);
            if (index > -1) {
                this.activeKeys.splice(index, 1); // Remove the key from the active keys array
            }
        },
        triggerAction(key) {
            // 각 키에 대한 이벤트 처리 로직을 여기에 추가
            console.log(`${key} key pressed. Current rotation angle: ${this.rotationAngle} degrees`);
        },
        applyRotation(key) {
            // 축 회전 상태를 저장할 변수
            let rotationAxis = 'X';

            // Z와 V 버튼의 회전 각도가 45도 이상일 때 Y축 회전으로 변경
            if (Math.abs(this.rotationCameraAngle % 360) >= 45) {
                rotationAxis = 'Y';
            }
            if (Math.abs(this.rotationCameraAngle % 360) >= 180) {
                rotationAxis = 'X';
            }
            if (Math.abs(this.rotationCameraAngle % 360) >= 270) {
                rotationAxis = 'Y';
            }

            if (key === 'Z') {
                this.rotationCameraAngle = (this.rotationCameraAngle - this.rotationStep) % 360; // Z축 반시계방향 회전
            } else if (key === 'V') {
                this.rotationCameraAngle = (this.rotationCameraAngle + this.rotationStep) % 360; // Z축 시계방향 회전
            } else if (key === 'X') {
                if (rotationAxis === 'Y') {
                    this.rotationYAxisAngle = (this.rotationYAxisAngle - this.rotationStep) % 360; // X키가 Y축으로 회전
                } else {
                    this.rotationXAxisAngle = (this.rotationXAxisAngle - this.rotationStep) % 360; // X키가 X축으로 회전
                }
            } else if (key === 'C') {
                if (rotationAxis === 'Y') {
                    this.rotationYAxisAngle = (this.rotationYAxisAngle + this.rotationStep) % 360; // C키가 Y축으로 회전
                } else {
                    this.rotationXAxisAngle = (this.rotationXAxisAngle + this.rotationStep) % 360; // C키가 X축으로 회전
                }
            }

            // 만약 각도가 음수가 되면, 이를 360도로 되돌립니다.
            if (this.rotationCameraAngle < 0) this.rotationCameraAngle += 360;
            if (this.rotationXAxisAngle < 0) this.rotationXAxisAngle += 360;
            if (this.rotationYAxisAngle < 0) this.rotationYAxisAngle += 360;

            console.log(`Key: ${key}, Rotation Camera Angle: ${this.rotationCameraAngle}, Rotation X-Axis Angle: ${this.rotationXAxisAngle}, Rotation Y-Axis Angle: ${this.rotationYAxisAngle}`);
        },
        toggleDroneActive(index) {
            //this.$set(this.isActive, index, !this.isActive[index]);  // 해당 버튼의 활성화 상태를 토글
            if(index === 3){
                window.confirm("현재 권한이 없습니다.");
            }else {
                this.$set(this.isActive, index, !this.isActive[index]);
            }
        },

        //station btn
        confirmFlightStart() {
            const userConfirmed = window.confirm("비행을 시작하시겠습니까?");
            if (userConfirmed) {
                this.isFlightStarted = true;  // 확인을 누르면 비행 시작 상태로 전환
            }
        },
        confirmFlightStop() {
            const userConfirmed = window.confirm("비행을 중지하시겠습니까?");
            if (userConfirmed) {
                this.isFlightStarted = false;  // 확인을 누르면 비행 중지 상태로 전환
            }
        },
        handleDoorOpen() {
            const userConfirmed = window.confirm("도어를 여시겠습니까?");
            if (userConfirmed) {
                this.modalMessage = "도어를 여는 중...";
                this.isModalVisible = true; // 모달 창 표시
                setTimeout(() => {
                this.isModalVisible = false; // 모달 창 닫기
                this.isDoorOpened = true; // 도어 열린 상태로 변경
                }, 3000); // 3초 후 모달 닫기
            }
        },
        handleDoorClose() {
            const userConfirmed = window.confirm("도어를 닫으시겠습니까?");
            if (userConfirmed) {
                this.modalMessage = "도어를 닫는 중...";
                this.isModalVisible = true; // 모달 창 표시
                setTimeout(() => {
                this.isModalVisible = false; // 모달 창 닫기
                this.isDoorOpened = false; // 도어 닫힌 상태로 변경
                }, 3000); // 3초 후 모달 닫기
            }
        },
        openCoordinateModal() {
            this.isCoordinateOpen = true;
        },
        coordinateCloseModal() {
            this.isCoordinateOpen = false;
        },

        //RTH mode
        handleRTHClick() {
            if (this.isFlashing) {
                // 깜박거림이 진행 중일 때 다시 버튼을 누르면 중단 여부를 묻는 알림을 띄움
                const stopFlashing = confirm("RETURN-TO-HOME을 중단 하시겠습니까?");
                if (stopFlashing) {
                    this.stopFlashing(true);  // 중단 시 true 값을 전달
                    this.showRTHStopModal();
                }
            } else {
                // 깜박거림이 진행 중이 아닐 때 RETURN-TO-HOME 실행 여부를 물어봄
                if (confirm("RETURN-TO-HOME을 실행 하시겠습니까?")) {
                    this.startFlashing();
                }
            }
        },
        startFlashing() {
            this.isFlashing = true;
            this.isRTHCancelled = false; // 중단 여부를 추적하는 플래그

            // 깜박거림을 시작
            this.flashingInterval = setInterval(() => {
                this.isFlashing = !this.isFlashing;
            }, 5000);

            // 5초 후에 깜박거림을 멈추고, 중단되지 않았다면 완료 모달을 표시
            this.flashingTimeout = setTimeout(() => {  // 타이머를 변수에 저장하여 나중에 취소 가능
                if (!this.isRTHCancelled) {  // 중단되지 않은 경우에만 완료 모달 표시
                    this.stopFlashing(false);  // false 값을 전달하여 자동 종료 처리
                    this.showRTHModal();  // 자동 종료 후 완료 모달을 표시
                }
            }, 5000);
        },
        stopFlashing(userStopped){
            clearInterval(this.flashingInterval); // 깜박거림을 멈춤
            clearTimeout(this.flashingTimeout); // 이전 타이머 취소
            this.isFlashing = false;

            if (userStopped) {
                this.isRTHCancelled = true;  // 중단되었음을 표시
                this.isRTHStopModalOpen = true;  // 중단 모달을 염
            } else {
                this.isRTHCancelled = false; // 자동 종료 시에는 취소되지 않았음을 표시
            }
        },
        showRTHModal() {
            this.isRTHModalOpen = true; // 모달을 염
        },
        closeRTHModal() {
            this.isRTHModalOpen = false; // 모달을 닫음
        },
        showRTHStopModal() {
            this.isRTHStopModalOpen = true; // 모달을 염
        },
        closeRTHStopModal() {
            this.isRTHStopModalOpen = false; // 모달을 닫음
        },
        switchViews() {

            const bigViewElement = this.$refs.bigView;
            const smViewElement = this.$refs.smView;

            const mapElement = this.$refs.map;
            const videoElement = this.$refs.video;

            // bigViewElement.appendChild(videoElement)
            // smViewElement.appendChild(mapElement)
            if (bigViewElement.contains(videoElement)) {
                smViewElement.appendChild(videoElement);
                bigViewElement.appendChild(mapElement);

                this.$refs.video.style.height = '100%';
                this.$refs.wideData.style.top = '20px';
            } else {
                // videoElement가 smViewElement에 있는 경우, 위치를 스위칭
                bigViewElement.appendChild(videoElement);
                smViewElement.appendChild(mapElement);

                this.$refs.smView.style.flexDirection = 'column-reverse';
                this.$refs.wideData.style.top = '80px';
            }
        },

    },

};
</script>

